import React from 'react'

function GroupClassesList() {
  return (
      <>
          <div className="groupListContainer">
              <div className="classContainer classContainerodd">
                  <h4>Kettlebell Burn</h4>
                  <p>
                      Kettlebell Burn is a dynamic full-body strength class
                      designed to challenge and tone every muscle.
                  </p>
                  <div className="classInfo">
                      <p>Saturdays at 11 am</p>
                      <p>Classes start January 11th 2025</p>
                  </div>
              </div>
              <div className="classContainer">
                  <h4>Yin & Yang Flow</h4>
                  <p>
                      The first half of the class is spent on building heat
                      through the body through power flows. The second half of
                      the class in deep and restorative postures.
                  </p>
                  <div className="classInfo">
                      <p>Saturdays at 9 am</p>
                      <p>Classes start January 18th 2025</p>
                  </div>
              </div>
              <div className="classContainer classContainerodd">
                  <h4>Strength and Conditioning</h4>
                  <p>
                      This class combines weightlifting, functional movements,
                      and cardio drills to build muscle, burn fat, and enhance
                      overall athletic performance.
                  </p>
                  <div className="classInfo">
                      <p>Sundays at 9 am</p>
                      <p>Classes start January 12th 2025</p>
                  </div>
              </div>
              <div className="classContainer">
                  <h4>Vinyasa Strength</h4>
                  <p>
                      An active and flowing yoga class focused on building
                      strength and flexibility. You are encouraged to connect
                      with your breath as we move through different creative
                      sequences.
                  </p>
                  <div className="classInfo">
                      <p>Tuesdays at 7 am</p>
                      <p>Classes start January 21st 2025</p>
                  </div>
              </div>
              <div className="lastClassList">
                  <h4>Sculpt and Strengthen</h4>
                  <p>
                      This program alternates weekly between lower and upper
                      body workouts, using targeted exercises designed to
                      maximize muscle growth. Perfect for those looking to
                      increase size, strength, and overall performance in a fun,
                      supportive group setting.
                  </p>
                  <div className="classInfo">
                      <p>Thursdays at 7:30 pm</p>
                      <p>Classes start January 16th 2025</p>
                  </div>
              </div>
          </div>
      </>
  );
}

export default GroupClassesList