import React from 'react'
import { useRef, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const MembershipOptions = () => {
    const location = useLocation();
    const elementRef = useRef(null);

    const [expand1, setExpand1]=useState(false)
    const [expand2, setExpand2] = useState(false);

    useEffect(
        function () {
            if (location.pathname === "/memberships/membershipOptions") {
                elementRef.current.scrollIntoView({
                    behavior: "smooth",
                });
            }
        },
        [location]
    );
  return (
      <>
          <div
              className="membershipOptionTable"
              id="memberships"
              ref={elementRef}
          >
              <div className="membershipOption option1">
                  <h2>GOLD</h2>
                  <div className="optionInfo">
                      <h4>SINGLE PERSON UNLIMITED MONTHLY MEMBERSHIP</h4>
                      <div className="membershipCost">
                          <h3>$150</h3>
                          <p>per month + tax</p>
                      </div>
                      <div
                          className="membershipFeatures"
                          onClick={() => {
                              setExpand1(!expand1);
                          }}
                      >
                          {expand1 ? (
                              <>
                                  <div className="straightLine"></div>
                                  <div>
                                      <p>
                                          A great option for anyone wanting to
                                          work out on their own
                                      </p>
                                      <p>
                                          Unlimited access to group classes
                                          (based on availability)
                                      </p>
                                      <p>Access to coworking & lounge spaces</p>
                                  </div>
                              </>
                          ) : (
                              <div className="crossSign"></div>
                          )}
                      </div>

                      <button>
                          <a href="https://www.wellnessliving.com/rs/catalog-view.html?k_business=416410&id_sale=5&k_id=3446139">
                              Sign Up
                          </a>
                      </button>
                  </div>
              </div>
              <div className="membershipOption">
                  <h2>PLATINUM</h2>
                  <div className="optionInfo">
                      <h4>BUDDIES & BUSINESSES UNLIMITED MONTHLY MEMBERSHIP</h4>
                      <div className="membershipCost">
                          <h3>$250</h3>
                          <p>per month + tax</p>
                      </div>
                      <div
                          className="membershipFeatures"
                          onClick={() => {
                              setExpand2(!expand2);
                          }}
                      >
                          {expand2 ? (
                              <>
                                  <div className="straightLine"></div>
                                  <div>
                                      <p>
                                          A great option for couples/friends
                                          wanting to workout together or fitness
                                          professionals wanting to host 1-1
                                          personal training sessions.
                                      </p>
                                      <p>
                                          Unlimited access to group classes
                                          (based on availability)
                                      </p>
                                      <p>Access to coworking & lounge spaces</p>
                                  </div>
                              </>
                          ) : (
                              <div className="crossSign"></div>
                          )}
                      </div>
                      <button>
                          <a href="https://www.wellnessliving.com/rs/catalog-view.html?k_business=416410&id_sale=5&k_id=3446191">
                              Sign Up
                          </a>
                      </button>
                  </div>
              </div>
          </div>
      </>
  );
}

export default MembershipOptions